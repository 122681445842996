import React, { ReactElement } from "react"
import Lottie from "lottie-react-web"
import animation from "../images/14331-error.json"

const NotFound = () => {
  return (
    <>
      <div className="">
        <nav className="flex flex-wrap items-center justify-between p-6 bg-gray-300">
          <div className="flex items-center flex-shrink-0 mr-6 text-white"></div>
        </nav>
      </div>

      <div className="w-full" id="form2">
        <div className="text-base sm:text-2xl text-center block  tracking-wide text-gray-700 py-4">
          指定されたページは存在しません
        </div>
        <div className="text-center">
          <Lottie
            options={{ animationData: animation, loop: false }}
            height={400}
          />
          <button
            className="w-full sm:w-1/3 shadow bg-gradient-to-r from-pink-500 to-yellow-500 hover:opacity-70 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            type="submit"
            onClick={() => {
              if (window.history) window.history.back()
            }}
          >
            トップへ戻る
          </button>
        </div>
      </div>
    </>
  )
}

export default NotFound
